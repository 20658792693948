@import "../../styles/theme.scss";

.tag-dcs {
  border-radius: 7px;
  padding: 7px 10px;
  font-weight: bold;
  font-size: 14px;
}

.tag-dcs.primary{
  background-color: $primary-color-light;
  color: $primary-color;
}

.tag-dcs.success{
  background-color: $success-color-light;
  color: $success-color;
}

.tag-dcs.warning{
  background-color: $warning-color-light;
  color: $warning-color;
}

.tag-dcs.danger{
  background-color: $danger-color-light;
  color: $danger-color;
}

.tag-dcs.default{
  background-color: $default-color-light;
  color: $default-color;
}

.tag-dcs.pending{
  background-color: $pending-color-light;
  color: $pending-color;
}