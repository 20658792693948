@import "../../styles/theme.scss";

.card-user-profile {

  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: 120px;
      margin-bottom: 15px;
    }

    .user-name {
      font-weight: bold;
      color: $primary-color;
      font-size: 22px;
    }
  }

  .profile-item {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border: none;
    }

    font-size: 18px;

    .user-role {
      margin-left: 5px;
    }
  }

}