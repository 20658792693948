@import '../../../styles/theme.scss';

.card-report-schedule-form {
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 0;
    }
  }

  .ant-col {
    padding: 0 5px;
  }

  form {
    margin-top: 15px;
  }

  label {
    padding-left: 15px;
    font-weight: bold;
    color: $text-color;
  }

  .input-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    .dcs-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 38px;
    }
  }

  .card-list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      color: $primary-color;
      font-weight: bold;
    }

    .contact-length {
      background-color: $primary-color;
      color: #fff;
      border-radius: 4px;
      padding: 3px 10px;
    }

    padding-bottom: 7px;
    border-bottom: 2px solid $primary-color;
  }

  .contact-form {
    button {
      width: 100%;
    }
  }
}
.email-error {
  color: #ff4d4f;
}
