@import "../../styles/theme.scss";

.contact-item {

  margin: 7px 0;
  border-radius: 7px;
  background-color: #e1e8ff;
  padding: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .contact-user {
    display: flex;
    flex-direction: row;
    align-items: center;

    .contact-avatar {
      font-size: 22px;
      font-weight: bold;
      color: #fff;
      background-color: $primary-color;
      border-radius: 7px;

      height: 45px;
      width: 45px;

      margin-right: 10px;
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .contact-info-name {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          color: $primary-color;
          margin-right: 5px;
        }

        span {
          color: $primary-color;
          font-weight: bold;
          font-size: 16px;
        }
      }

      .contact-info-email {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          color: $primary-color;
          margin-right: 5px;
        }

        span {
          color: $primary-color;
          font-size: 14px;
        }
      }
    }
  }

  .contact-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-remove {
      color: rgb(202, 2, 2);
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        color: rgb(177, 0, 0);
        font-size: 15px;
      }
    }
  }
  
}