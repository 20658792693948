@import "../../styles/theme.scss";

.general-settings-menu {
  // background-color: #f0f3ff;
  // padding: 10px;
  ul {
    list-style: none;
  }

  .general-settings-menu-tree {
    color: #999;
    font-weight: 500;
    margin-bottom: 7px;
  }

  .general-settings-menu-item {
    margin-left: 10px;
    color: $primary-color;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: $primary-color-dark;
    }
  }

  .disabled {
    color: rgb(214, 214, 214);
    cursor: no-drop;

    &:hover {
      color: rgb(214, 214, 214);
    }
  }
}