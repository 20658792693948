@import '../../styles/theme.scss';

.card-reports {
  .ant-col {
    padding: 5px;
  }

  form {
    margin-top: 15px;
    label {
      padding-left: 15px;
      font-weight: bold;
      color: $text-color;
    }

    .dcs-input {
      width: 100%;
    }
  }

  /*button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }*/
}
.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #283593;
  margin-bottom: 10px;

  h2 {
    font-size: 20px;
    font-weight: bold;
    color: $primary-color;
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: 10px;
  }
}

.dashboard { 
  width: 100%;
  height: 700px;
}

.dashboard iframe {
  width: 100%;
  height:  calc(100% - 0px);
  border: none;
}
