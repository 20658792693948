@import '../../styles/theme.scss';

.header-wd {
  background: #fff;
  height: 80px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .menu-collapse-button {
    font-size: 26px;
    margin: 22px 16px;
  }

  .header-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;

    .notifications-item {
      margin-right: 10px;
      margin-top: 5px;

      .ant-badge-count {
        margin-right: 10px;
      }

      svg {
        cursor: pointer;
      }
    }

    .new-schedule-button {
      svg {
        margin: 0;
      }

      margin-right: 20px;
    }

    svg {
      font-size: 18px;
      margin: 0 16px;
    }

    .user-action {
      display: flex;
      align-items: center;

      img {
        height: 42px;
        margin-right: 7px;
      }

      svg {
        font-size: 12px;
        color: $primary-color;
      }

      cursor: pointer;
    }
  }
}

.notification-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;

  img {
    height: 40px;
    margin-right: 7px;
  }

  .notification-container-info {
    p {
      white-space: pre-line;
    }
  }
}