.general-setting-attachments {
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    svg {
      margin-right: 5px; 
    }
  }
}