@import '../../styles/theme.scss';

.table-dcs {
  overflow: overlay;
  margin: 20px 0px;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    h2 {
      font-weight: bold;
      font-size: 20px;
      color: $primary-color;
      padding-left: 10px;
      margin-bottom: 0;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    border-bottom: 2px solid $primary-color;
    margin-bottom: 20px;
  }

  .table-actions {
    width: 100%;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  table {
    width: 100%;

    th,
    td {
      text-align: center;
    }

    th {
      font-size: 15px;
      color: #9e9d9d;
      padding: 5px;
    }

    td {
      border-bottom: 1px solid #e7e7e7;
      padding: 20px 6px;
    }

    img {
      height: 55px;
    }
  }
  .column-iconSort {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 600px) {
    .table-column.not-responsive {
      display: none;
    }
  }
}
