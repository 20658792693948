@import "../../../styles/theme.scss";

.card-integration-config-form {

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }

  .page-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 0;
    }

  }

  .ant-col {
    padding: 0 5px;
  }

  form {
    margin-top: 15px;
  }

  label {
    padding-left: 15px;
    font-weight: bold;
    color: $text-color;
  }

  .input-list-config {    
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .dcs-input {
      width: 400px !important;
    }
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      svg {
        margin: 0;
      }
    }
  }

}