@import "../../styles/theme.scss";

.card-reports {
  .ant-col {
    padding: 5px;
  }


  form {
    label {
      padding-left: 15px;
      font-weight: bold;
      color: $text-color;
    }

    .dcs-input {
      width: 100%;
    }
  }

  /*button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }*/
}