@import "../../styles/theme.scss";

.contact-report-schedule-list-card {

  .ant-card-body {
    padding: 12px;
  }

  .card-list-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 18px;
      color: $primary-color;
      font-weight: bold;
    }

    .contact-length {
      background-color: $primary-color;
      color: #fff;
      border-radius: 4px;
      padding: 3px 10px;
    }

    padding-bottom: 7px;
    border-bottom: 2px solid $primary-color;
  }

  .card-list-body {

    height: 240px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color:#fff;
      width:16px
    }

    &::-webkit-scrollbar-track {
      background-color:#fff
    }
    &::-webkit-scrollbar-track:hover {
      background-color:#f4f4f4
    }
    
    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color:#babac0;
      border-radius:16px;
      border:5px solid #fff
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color:#a0a0a5;
      border:4px solid #f4f4f4
    }
    
    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {display:none}

    ul {
      list-style: none;
    }
  }

}