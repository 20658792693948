@import '../../styles/theme.scss';

.card-integration-form {
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 0;
    }
  }

  .ant-col {
    padding: 0 5px;
  }

  form {
    margin-top: 15px;
  }

  label {
    padding-left: 10px;
    font-weight: bold;
    color: $text-color;
    font-size: 12px;
  }

  .client_integration_row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .client_integration_col_action {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        svg {
          margin-right: 0;
        }
      }
    }
  }
}
