@import "../../styles/theme.scss";

aside {
  background-color: #fff o !important;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  background: #fff;

}

#components-layout-demo-custom-trigger .trigger:hover {
  color: $primary-color;
  background: #fff;

}



.site-layout .site-layout-background {
  background: #fff;
}

.sidebar-wd {
  height: auto !important;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  // height: 100vh !important;
  // height: 100vh !important;
  min-height: 100vh !important;
  height: max-content;

  .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
    overflow-y: auto;
    overflow-x: hidden;
  }


  .sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 10px;
    //width: 100%;
    background: #fff;


    p {
      font-weight: bold;
    }
  }

}

.app-layout {
  //height: 100%;
  background-color: #fff;


  .logo {
    background-color: #fff;
    text-align: center;

    img {
      height: 63px;
    }

    strong {
      font-size: 26px;
      color: $primary-color;

      span {
        color: #fff;
      }
    }

    margin: 7px;
  }

  .app-content {
    height: auto;
    min-height: calc(100% - 48px);
    margin: 24px 16px;
    padding: 24px;
    // background-color: #fff;
  }

  .menu-collapse-button {
    font-size: 26px;
    margin: 22px 16px;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
}