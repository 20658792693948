.public-container {
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  background: url("../../assets/background.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 70%;
  background-color: #f5f5f5;
}