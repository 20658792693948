$primary-color: #283593;
$primary-color-dark: #222d80;
$primary-color-light: #c0cfff;

$text-color: #65666d;
$text-color-light: #9e9d9d;

$danger-color: #f96262;
$danger-color-light: #fdd0d0;

$success-color: #4db456;
$success-color-light: #cae9cd;

$warning-color: #ffa53b;
$warning-color-light: #ffe4c5;

$default-color: #828284;
$default-color-light: #c3c5ce;

$pending-color: #0097a7;
$pending-color-light: #b2ebf2;