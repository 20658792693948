@import "../../styles/theme.scss";

.card-statistics {
  margin: 5px;
  background-color: $primary-color;
  border-radius: 4px;
  border: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .ant-card-body {
    padding: 20px;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        color: #C9E265;
        font-size: 16px;
      }
      .statistc {
        color: #fff;
        font-size: 30px;
        margin: 0;
      }
    }

  }
}