@import './theme.scss';

$input_color: #e8eefd;

.dcs-select {
  width: 200px;
}

.ant-select-selector {
  border: none !important;
  border-radius: 20px !important;
  min-height: 32px !important;
  align-items: center !important;
  background-color: #f1f1f1 !important;

  &:active {
    color: $primary-color !important;
    background-color: #e8eefd !important;
  }

  input {
    margin-top: 3px !important;
  }
}

.ant-select-selection-item {
  color: $primary-color !important;
}

.ant-select-arrow {
  color: $primary-color;
}

.ant-select-clear {
  color: $primary-color !important;
  background-color: #f1f1f1 !important;
  align-items: center !important;
}

.ant-select-item-option-active {
  background-color: #e8eefd;

  &:hover {
    background-color: #e8eefd;
  }

  .ant-select-item-option-content {
    background-color: $primary-color !important;
    color: #fff !important;
    border-radius: 30px;
    width: 70%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.ant-select-dropdown-placement-bottomLeft {
  background-color: #e8eefd;

  .ant-select-item-option-content {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.ant-select-selection-search-input {
  background-color: #f1f1f1;
  border-radius: 20px;
  border: 0;

  .ant-input-suffix {
    svg {
      color: $primary-color;
    }
  }
}

.ant-select-auto-complete.ant-select-focused {
  .ant-select-selector {
    background-color: #e8eefd !important;
    color: $primary-color !important;
  }
}

.dcs-radio {
  .ant-radio {
    .ant-radio-inner {
      height: 20px;
      width: 20px;
    }

    .ant-radio-inner::after {
      font-family: FontAwesome;
      top: 0;
      left: 0;
      padding: 0;
      margin: 0 !important;
      height: 18px;
      width: 18px;
      content: '\f00c';
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }
}

.dcs-input {
  background-color: #f1f1f1;
  caret-color: $primary-color;
  border-radius: 20px;
  min-height: 32px !important;
  border: none;
  font-size: 14px;
  width: 100%;
  
  &::placeholder {
    color: #bfbfbf;
    font-size: 14px;
    font-weight: 400;
    // font-family: 'Roboto', sans-serif;
  }
  padding: 0 11px;

  
  &:focus {
    background-color: #e8eefd;
    box-shadow: 0 0 0 2px rgba(0, 19, 60, 0.22) !important;
    outline: none;
  }

  .ant-input {
    background-color: #f1f1f1;
  }

  .anticon.anticon-search.ant-input-search-icon,
  .anticon.anticon-loading.ant-input-search-icon {
    svg {
      color: $primary-color;
    }

    &::before {
      display: none;
    }
  }
}

.dcs-input-textarea {
  border-radius: 20px;
  padding-top: 10px;
}

.dcs-password {
  background-color: #f1f1f1;
}

.ant-input-affix-wrapper-focused {
  background-color: #e8eefd;

  .ant-input {
    background-color: #e8eefd;
  }
}

.dcs-notification {
  color: #fff;
  border-radius: 7px;

  .ant-notification-notice-icon {
    background-color: #fdd0d0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;

    svg {
      color: #f96262;
      font-size: 16px;
    }
  }

  .ant-notification-notice-message {
    color: #fff;
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #fdd0d0;
    }
  }
}

.dcs-notification-error {
  background-color: #f96262;

  .ant-notification-notice-icon {
    background-color: #fdd0d0;

    svg {
      color: #f96262;
    }
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #fdd0d0;
    }
  }
}

.dcs-notification-success {
  background-color: #4db456;

  .ant-notification-notice-icon {
    background-color: #cae9cd;

    svg {
      color: #4db456;
      font-size: 16px;
    }
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #cae9cd;
    }
  }
}

.dcs-notification-primary {
  background-color: $primary-color;

  .ant-notification-notice-icon {
    background-color: #e4e6f0;

    svg {
      color: $primary-color;
      font-size: 16px;
    }
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #e4e6f0;
    }
  }
}

.dcs-notification-secondary {
  background-color: #e4e6f0;

  .ant-notification-notice-message {
    color: #828284 !important;
  }

  .ant-notification-notice-content {
    color: #828284 !important;
  }

  .ant-notification-notice-icon {
    background-color: #c3c5ce;

    svg {
      color: #e4e6f0;
      font-size: 16px;
    }
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #c3c5ce;
    }
  }
}

.dcs-notification-info {
  background-color: #4ea0ed;

  .ant-notification-notice-icon {
    background-color: #cae3fa;

    svg {
      color: #4ea0ed;
      font-size: 16px;
    }
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #cae3fa;
    }
  }
}

.dcs-notification-warning {
  background-color: #ffa53b;

  .ant-notification-notice-icon {
    background-color: #ffe4c5;

    svg {
      color: #ffa53b;
      font-size: 16px;
    }
  }

  .ant-notification-notice-close {
    .anticon.anticon-close.ant-notification-close-icon {
      color: #ffe4c5;
    }
  }
}

.collapse-dcs {
  margin: 14px 0px 0px 15px;
  background-color: #f1f1f1;
}

.collapse-panel-dcs {
  background-color: #f1f1f1;
}

.line-header-env {
  height: 7px;
  width: 100%;
  position: absolute;
  z-index: 5;
}

.mask-input {
  padding-left: 0.6rem;
}

.json-content {
  overflow-x: auto;
  max-width: 50rem;
}
