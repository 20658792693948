@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html, body, #root {
  height: 100%;
  // background: url("../assets/shape-webdoctor.svg");
  // background-repeat: no-repeat;
  // background-position: top right;
  // background-size: 65%;
}


@media only screen and (max-width: 767px) {
  // body {
  //   background: url("../assets/shape-webdoctor.svg");
  //   background-repeat: no-repeat;
  //   background-position: top right;
  //   background-size: 100%;
  // }
}