.general-settings-body {
  padding: 0 20px;

  .title {
    color: #000;
    font-weight: bold;
    font-size: 22px;
  }

  .general-settings-divider {
    margin: 10px 0;
  }
  .body {
    margin-top: 30px;
  }
}