@import "../../../styles/theme.scss";

.dcs-spin {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}

.dcs-divider-text {
  font-weight: bold;
  color: #65666d;
  font-size: 14px;
}

.card-user-form {

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }

  .page-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 0;
    }

  }

  .ant-col {
    padding: 0 5px;
  }

  form {
    margin-top: 15px;
  }

  label {
    padding-left: 15px;
    font-weight: bold;
    color: $text-color;
  }

}