.license-form {
  .version-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}

h3 {
  font-size: 16px;
  font-weight: 600;
  color: #283593;
  margin-bottom: 1rem;
}

.ant-alert-message {
  color: #65666d;
  border-radius: 20px;
}

.custom-required-item {
  .css-dev-only-do-not-override-7k3g39.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }
}
