@import "../../styles/theme.scss";

.reset-password-page {

  height: 100%;
  display: flex;
  max-width: 700px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .ant-card {
    border-radius: 10px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);

    .ant-card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  h1 {
    font-weight: bold;
    color: $primary-color;
    font-size: 20px;
  }

  .reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-reset-password {
    margin-top: 30px;
    width: 350px;

    label {
      padding-left: 15px;
    }

    .reset-password-actions {
      text-align: center;

      button {
        width: 200px;
        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
      }
    }

  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-weight: bold;
      font-size: 26px;
    }

    .form-reset-password {
      width: 360px;
    }
  
  }

}