@import "../../../styles/theme.scss";

.card-hsm-detail {
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
    }
  }

  .page-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 0;
    }

  }
}

.card-hsm-history {

  margin-top: 20px;

  .page-header {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 0;
    }

  }

}